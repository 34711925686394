@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

:focus {
  outline-color: transparent !important;
  box-shadow: none !important;
}

a[role=button] {
  text-decoration: none;
}

button:focus {
  outline: none;
}

button,
[role="button"] {
    cursor: pointer;
}

button,
input,
optgroup,
select,
textarea {
    padding: 0;
    line-height: inherit;
    color: inherit;
}

pre,
code,
kbd,
samp {
    font-family: Consolas, "Liberation Mono", Menlo, Courier, monospace;
}

img,
video {
    max-width: 100%;
    height: auto;
}

.game-card-images {
  height: 150px;
  -o-object-fit: fill!important;
     object-fit: fill!important;
}